<template>
  <v-sheet color="grey lighten-3" class="fill-height">
    <!-- {{ wagonCountDiscrepancy }} {{ utiCountDiscrepancy }} -->
    <v-container fluid fill-height class="pa-0 ma-0">
      <v-row class="fill-height pa-0 ma-0">
        <v-col cols="12" align-self="start" class="pa-0 ma-0">
          <v-container fluid fill-height :class="headerColor" style="height: 60px">
            <v-row>
              <v-col v-if="toBeRendered" cols="5" class="d-flex justify-top">
                <v-icon color="white">mdi-ray-end</v-icon>
                <span class="white--text text-h5 font-weight-light text-uppercase pl-2">{{ trainComposition.trainDate | formatDateTimeNoYearFromDate }}</span>
              </v-col>
              <v-col cols="5" v-else />
              <v-col cols="7" class="d-flex justify-end">
                <span class="white--text text-h5 font-weight-light text-uppercase pr-2">{{ headerLabel }}</span>
                <v-icon color="white">{{ headerIcon }}</v-icon>               
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col v-if="toBeRendered" cols="12" align-self="center" class="pa-0 ma-0" style="height: calc(100vh - 342px); overflow-y: auto">
          <v-timeline>
            <v-timeline-item v-for="wagon in wagons" :key="wagon.code" right :color="wagon.discrepancy && wagon.discrepancy === true ? 'error' : 'grey'">
              <template v-slot:icon>
                <span class="white--text text-caption">{{ wagon.position }}</span>
              </template>
              <div slot="opposite">
                <input :size="maxWagonCodeLength" :class="`${wagonInputClass(wagon)} text-h5 text-center`" :ref="`wagon_${wagon.code}`" type="text" :value="wagon.code" readonly />
              </div>
              <div :class="`${utiDivClass(unit.discrepancy)} elevation-2 ma-2 pa-1 d-flex justify-center`" v-for="unit of wagon.units" :key="unit.code">
                <input :class="`${utiInputClass(unit)} text-subtitle-1 text-center`" :ref="`unit_${unit.code}`" type="text" :value="unit.code" readonly />
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-col>
        <v-col v-if="toBeRendered" cols="12" align-self="end" class="pa-0 ma-0">
          <v-container fill-height class="grey lighten-2" style="height: 40px">
            <v-row align="center">
              <v-col cols="6" class="text-body-2 text-uppercase d-flex justify-top">
                {{ searchUtiCarroResultRefs.length === 0 ? 0 : searchUtiCarroResultRefsIndex + 1 }} / {{ searchUtiCarroResultRefs.length }}
                <v-icon small class="pl-2" :disabled="searchUtiCarroResultRefsIndex === 0" @click="focusOnPrev">mdi-chevron-up</v-icon>
                <v-icon
                  small                  
                  :disabled="searchUtiCarroResultRefs.length > 0 ? searchUtiCarroResultRefsIndex + 1 === searchUtiCarroResultRefs.length : true"
                  @click="focusOnNext"
                  >mdi-chevron-down</v-icon
                >               
              </v-col>
              <v-col cols="6" class="text-body-2 text-uppercase d-flex justify-end">
                <span :class="wagonCountDiscrepancy === true ? 'font-weight-bold' : 'font-weight-light'">Carri:</span>
                <span :class="wagonCountDiscrepancy === true ? 'font-weight-bold text-red-darken' : 'font-weight-light'">{{ countCarri }}</span>
                &nbsp;
                <span :class="utiCountDiscrepancy === true ? 'font-weight-bold' : 'font-weight-light'">UTI:</span>
                <span :class="utiCountDiscrepancy === true ? 'font-weight-bold text-red-darken' : 'font-weight-light'">{{ countUti }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-btn v-if="rrStatus && rrStatus !== null" fab x-large class="floatt" elevation="9" :color="headerColor">
      <v-icon large>{{ rrEsitoBtnIcon }}</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
import FormatsMixins from "../../../mixins/FormatsMixins";

export default {
  name: "TrainCompositionSheet",
  data() {
    return {
      searchUtiCarroResultRefs: [],
      searchUtiCarroResultRefsIndex: 0,
      utiCarroRefSelected: null,
      wagons: [],
    };
  },
  props: {
    trainComposition: {
      type: Object,
      required: false,
      default: null,
    },
    trainCompositionType: {
      type: String,
      required: true,
    },
    wagonCountDiscrepancy: {
      type: Boolean,
      default: false,
    },
    utiCountDiscrepancy: {
      type: Boolean,
      default: false,
    },
    rrStatus: {
      type: String,
      required: false,
      default: null,
    },
    source: {
      type: String,
      required: false,
      default: null,
    },
  },
  mixins: [FormatsMixins],
  mounted() {
    if (
      this.trainComposition &&
      this.trainComposition !== null &&
      this.trainComposition.wagons &&
      this.trainComposition.wagons !== null &&
      this.trainComposition.wagons.length > 0
    ) {
      this.wagons = JSON.parse(JSON.stringify(this.trainComposition.wagons));
      this.wagons = this.wagons.sort((a, b) => (a.position > b.position ? 1 : -1));
    }
  },
  async created() {
    this.$eventBus.$on("searchUtiCarro", (carroUtiSearchFieldValue) => {
      this.searchUtiCarroResultRefs = [];
      this.utiCarroRefSelected = null;
      this.searchUtiCarroResultRefsIndex = 0;
      console.log("searchUtiCarro carroUtiSearchFieldValue", carroUtiSearchFieldValue);
      if (carroUtiSearchFieldValue && carroUtiSearchFieldValue !== null && carroUtiSearchFieldValue.length > 0) {
        if (this.wagons && this.wagons !== null && this.wagons.length > 0) {
          let filteredWagons = this.wagons.filter((w) => w.code.toLowerCase().startsWith(carroUtiSearchFieldValue.toLowerCase()));
          if (filteredWagons && filteredWagons !== null && filteredWagons.length > 0) {
            filteredWagons.forEach((fw) => {
              console.log("wagon ref to focus ---> ", "wagon_" + fw.code);
              this.searchUtiCarroResultRefs.push("wagon_" + fw.code);
            });
          }
          this.wagons.forEach((w) => {
            if (w.units && w.units !== null && w.units.length > 0) {
              let filteredUnits = w.units.filter((u) => u.code.toLowerCase().startsWith(carroUtiSearchFieldValue.toLowerCase()));
              if (filteredUnits && filteredUnits !== null && filteredUnits.length > 0) {
                filteredUnits.forEach((fu) => {
                  console.log("unit ref to focus ---> ", "unit_" + fu.code);
                  this.searchUtiCarroResultRefs.push("unit_" + fu.code);
                });
              }
            }
          });
        }
      }
      console.log("elementssss to focus ---> ", this.searchUtiCarroResultRefs);
      if (this.searchUtiCarroResultRefs.length > 0) {
        this.utiCarroRefSelected = this.searchUtiCarroResultRefs[0];
        this.$refs[this.searchUtiCarroResultRefs[this.searchUtiCarroResultRefsIndex]][0].focus();
      }
    });
  },
  computed: {
    toBeRendered() {
      return this.trainComposition && this.trainComposition !== null && this.trainComposition.voyage && this.trainComposition.voyage !== null;
    },
    headerColor() {
      if (this.trainCompositionType && this.trainCompositionType !== null && this.trainCompositionType === "PASSAGE") {
        if (this.rrStatus && this.rrStatus !== null) {
          if (this.rrStatus === "OK") {
            return "success";
          }
          if (this.rrStatus === "KO") {
            return "warning";
          }
        }
        return "primary";
      }
      return "grey";
    },
    headerLabel() {
      if (this.trainCompositionType && this.trainCompositionType !== null) {
        if (this.trainCompositionType === "PASSAGE") {
          if (this.rrStatus && this.rrStatus !== null) {
            return this.tLabel("invio al tos");
          }
          return this.tLabel("passaggio al varco");
        } else if (this.trainCompositionType === "PREADVICE") {
          if (this.source && this.source !== null) {
            if (this.source === 'COP') {
              return this.tLabel("preavviso da copino");
            } else if (this.source === 'API') {
              return this.tLabel("preavviso da portale");
            }
          } else {
            return this.tLabel("preavviso");
          }
        }
      }
      return null;
    },
    headerIcon() {
      if (this.trainCompositionType && this.trainCompositionType !== null) {
        if (this.trainCompositionType === "PASSAGE") {
          if (this.rrStatus && this.rrStatus !== null) {
            return "mdi-send";
          }
          return "mdi-cctv";
        } else if (this.trainCompositionType === "PREADVICE") {
          if (this.source && this.source !== null) {
            if (this.source === 'COP') {
              return "mdi-file-clock-outline";
            } else if (this.source === 'API') {
              return "mdi-web-clock";
            }
          }
        }
      }
      return null;
    },
    rrEsitoBtnIcon() {
      if (this.rrStatus && this.rrStatus !== null) {
        if (this.rrStatus === "KO") {
          return "mdi-alert";
        }
        return "mdi-check-bold";
      }
    },
    countCarri() {
      if (this.wagons && this.wagons !== null) {
        return this.wagons.length;
      }
      return 0;
    },
    countUti() {
      if (this.wagons && this.wagons !== null && this.wagons.length > 0) {
        let count = 0;
        this.wagons.forEach((wagon) => (count += wagon.units && wagon.units !== null ? wagon.units.length : 0));
        return count;
      }
      return 0;
    },
    maxWagonCodeLength() {
      var length = 0;
      if (this.wagons && this.wagons !== null && this.wagons.length > 0) {
        this.wagons.forEach((wagon) => {
          if (wagon.code.length > length) {
            length = wagon.code.length;
          }
        });
      }
      return length;
    },
  },
  methods: {
    utiDivClass(discrepancy) {
      if (discrepancy && discrepancy === true) {
        return "train-composition-uti-div-discrepancy";
      }
      return "train-composition-uti-div";
    },
    utiInputClass(unit) {
      var clazz = "";
      let unitRef = "unit_" + unit.code;
      if (this.searchUtiCarroResultRefs.includes(unitRef)) {
        if (this.utiCarroRefSelected === unitRef) {
          clazz = "input-filtered-selected ";
        } else {
          clazz = "input-filtered ";
        }
      }
      if (unit.discrepancy && unit.discrepancy === true) {
        return clazz + "text-red-darken font-weight-bold";
      }
      return clazz + "font-weight-light";
    },
    wagonInputClass(wagon) {
      var clazz = "";
      let wagonRef = "wagon_" + wagon.code;
      if (this.searchUtiCarroResultRefs.includes(wagonRef)) {
        if (this.utiCarroRefSelected === wagonRef) {
          clazz = "input-filtered-selected ";
        } else {
          clazz = "input-filtered ";
        }
      }
      if (wagon.discrepancy && wagon.discrepancy === true) {
        return clazz + "text-red-darken font-weight-bold";
      }
      return clazz + "font-weight-light";
    },
    focusOnNext() {
      this.searchUtiCarroResultRefsIndex++;
      this.utiCarroRefSelected = this.searchUtiCarroResultRefs[this.searchUtiCarroResultRefsIndex];
      this.$refs[this.searchUtiCarroResultRefs[this.searchUtiCarroResultRefsIndex]][0].focus();
    },
    focusOnPrev() {
      this.searchUtiCarroResultRefsIndex--;
      this.utiCarroRefSelected = this.searchUtiCarroResultRefs[this.searchUtiCarroResultRefsIndex];
      this.$refs[this.searchUtiCarroResultRefs[this.searchUtiCarroResultRefsIndex]][0].focus();
    },
  },
};
</script>

<style scoped>
.train-composition-uti-div {
  width: 200px;
  border-bottom: 1px solid #9e9e9e !important;
  border-right: 4px solid #9e9e9e !important;
}

.train-composition-uti-div-discrepancy {
  width: 200px;
  background-color: #ffebee;
  border-bottom: 1px solid #c62828 !important;
  border-right: 4px solid #c62828 !important;
}

.text-red-darken {
  color: #c62828 !important;
}

.floatt {
  position: absolute;
  top: 80%;
  left: 65%;
  opacity: 1;
}

.floatt:hover {
  position: absolute;
  top: 80%;
  left: 65%;
  opacity: 0.2;
}

input[type="text"] {
  outline: none;
}

input[type="text"]:focus {
  border: 0px solid;
}

input[type="text"]::selection {
  background: orange;
}

.input-filtered {
  background: yellow;
}

.input-filtered-selected {
  background: orange;
}
</style>
